import React, { Component } from 'react';
import CodingWithUniform from './partials/_CodingWithUniform';
import Introduction from './partials/_Introduction';
import WebTab from './_code-web';
import AndroidTab from './_code-android';
import AppleTab from './_code-apple';
import ReactNativeTab from './_code-reactnative';
import {
  IconCode,
  PageWithSubNav,
  Paragraph,
  PlatformTabs,
  Section,
} from '../../components';
import { setDesignCodePreference } from '../../utility/js/designCodePreference';
import styles from './index.module.scss';

export default class GetStartedCodeIndex extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setDesignCodePreference('code');
  }

  render() {
    return (
      <PageWithSubNav pageType="Code" title="Get Started" designCode>
        <Section>
          <div className={styles.mainTitle}>
            <IconCode className={styles.iconCode} />
            <CodingWithUniform />
          </div>
        </Section>

        <Section title="Introduction">
          <Introduction role="code" />
        </Section>

        <Section title="Installation">
          <Paragraph>
            Uniform is built with web in mind, but other platform components are
            being added all the time.{' '}
            <strong>Choose your platform for setup instructions</strong>.
          </Paragraph>

          <PlatformTabs
            pageType="resources"
            platforms={{
              web: <WebTab />,
              reactnative: <ReactNativeTab />,
              android: <AndroidTab />,
              apple: <AppleTab />,
            }}
          />
        </Section>
      </PageWithSubNav>
    );
  }
}
