import React from 'react';
import { CodeSnippet, Link, List, PlatformTab } from '../../components';

const snippet1 = require('raw-loader!../../data/snippets/get-started-rn1.example');
const snippet2 = require('raw-loader!../../data/snippets/get-started-rn2.example');
const snippet3 = require('raw-loader!../../data/snippets/get-started-rn3.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <List type="ordered">
        <li>
          Uniform, as with all RN apps at Hudl, uses Hudl's React Native library,{' '}
          <Link href="https://github.com/hudl/jarvis">Jarvis</Link>. Once you've
          set that up, head back here.
        </li>

        <li>
          Add <code>@hudl/rn-uniform</code> as a dependency in your app.
          <CodeSnippet
            code="yarn add @hudl/rn-uniform"
            disableCodeEditing
            className="uni-margin--one--top"
          />
        </li>

        <li>
          Import the necessary components from Uniform.
          <CodeSnippet
            code={snippet1}
            platform="web"
            disableCodeEditing
            className="uni-margin--one--top"
          />
        </li>

        <li>
          You can use the colors in your stylesheets.
          <CodeSnippet
            code={snippet2}
            platform="web"
            disableCodeEditing
            className="uni-margin--one--top"
          />
        </li>

        <li>
          Use the components as you would any other component.
          <CodeSnippet
            code={snippet3}
            platform="web"
            disableCodeEditing
            className="uni-margin--one--top"
          />
        </li>
      </List>
    </PlatformTab>
  );
};

export default ReactNativeTab;
