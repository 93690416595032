import React from 'react';
import { CodeSnippet, Link, List, PlatformTab } from '../../components';

const snippet1 = require('raw-loader!../../data/snippets/get-started-apple1.example');
const snippet2 = require('raw-loader!../../data/snippets/get-started-apple2.example');
const snippet3 = require('raw-loader!../../data/snippets/get-started-apple3.example');

const AppleTab = () => {
  return (
    <PlatformTab>
      <List type="ordered">
        <li>
          <Link href="https://github.com/hudl/modi">Modi</Link>, our primary iOS
          repo, already has the <code>ios-uniform-ui</code> CocoaPod installed.
          When working in a different iOS repo, edit the <code>Podfile</code>{' '}
          file.
          <CodeSnippet
            code={snippet1}
            platform="apple"
            disableCodeEditing
            className="uni-margin--one--top"
          />
        </li>

        <li>
          In terminal, <code>cd</code> to your project’s directory and run.
          <CodeSnippet
            code={snippet2}
            platform="apple"
            disableCodeEditing
            className="uni-margin--one--top"
          />
        </li>

        <li>
          Import Uniform at the top of your file.
          <CodeSnippet
            code={snippet3}
            platform="apple"
            disableCodeEditing
            className="uni-margin--one--top"
          />
        </li>

        <li>
          Copy any component snippets, like{' '}
          <Link href="/components/notifications/toast" isDesignCodeLink>
            toasts
          </Link>
          , to get started.
        </li>
      </List>
    </PlatformTab>
  );
};

export default AppleTab;
