import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  Paragraph,
  PlatformTab,
  Section,
  SectionSubhead,
} from '../../components';

const snippet1 = require('raw-loader!../../data/snippets/get-started-web1.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <Paragraph className="uni-margin--two--bottom">
        Uniform is automatically distributed through our UI system for easy
        setup and maintenance. Every current and future Hudl repo will be setup
        automatically.
      </Paragraph>

      <Section>
        <SectionSubhead>React</SectionSubhead>
        <Paragraph>
          Our full suite of components is built in React and is the preferred
          method for building interfaces.
        </Paragraph>
        <List type="ordered">
          <li>
            Adding Uniform to your repo:
            <CodeSnippet
              code={snippet1}
              platform="react"
              disableCodeEditing
              className="uni-margin--one--top"
            />
          </li>

          <li>
            Use any component snippets, like{' '}
            <Link href="/components/type/text" isDesignCodeLink>
              text
            </Link>
            , to get started.
          </li>
        </List>
      </Section>

      <Section>
        <SectionSubhead>CSS Styling</SectionSubhead>
        <Paragraph>
          The CSS included on every page includes variables for things like
          space, color, and z-indexes. Reference Uniform variables, like{' '}
          <Link href="/guidelines/colors/identity" isDesignCodeLink>
            colors
          </Link>
          , to get started. Other styling helpers like classes are available on
          the <Link href="/resources/code-helpers">code helpers</Link> page.
        </Paragraph>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
